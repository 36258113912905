import { ChangeEvent, FC, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { CustomTopicsSteps } from '../'
import useStyles from '../styles'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import loadJSONs, { WordBaseData } from '../../../utils/loadJSONs'
import DraggableList from '../components/DraggableList'
import {
  OrganizationTopic,
  PayloadSyncOrganizationTopicsRequestV5,
} from '@drops-developers/intersection'
import { useAuth } from '../../../providers/auth'
import { useQueryClient } from 'react-query'
import { CustomTopicsApiQueries } from '../../../types'
import AlertDialog from '../../../components/AlertDialog'

interface EditTopicStepProps {
  changeStep: (step: CustomTopicsSteps) => void
  email: string
  selectedTopic: OrganizationTopic | null
  topics: OrganizationTopic[]
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 500,
})

const EditTopicStep: FC<EditTopicStepProps> = ({
  changeStep,
  email,
  selectedTopic,
  topics,
}) => {
  const {
    invokeCustomTopicsApi,
    invokeDeleteOrganizationTopicV5,
    invokeDeleteOrganizationTopicWordV5,
  } = useAuth()
  const queryClient = useQueryClient()
  const classes = useStyles()
  const [topicName, setTopicName] = useState(selectedTopic?.title ?? '')
  const [topicId] = useState(selectedTopic?.id ?? uuidv4())
  const [wordsData, setWordsData] = useState<WordBaseData[]>([])
  const [selectedWords, setSelectedWords] = useState<WordBaseData[]>([])
  const [lastPosition, setLastPosition] = useState(0)
  const isLimitExceeded = selectedWords.length > 30

  useEffect(() => {
    const allData = loadJSONs()
    setWordsData(allData)
    if (selectedTopic) {
      const initialWords: WordBaseData[] = []
      selectedTopic.words.forEach((word) => {
        const findWord = allData.find((x) => x.wordId === word.wordId)
        if (findWord) {
          initialWords.push(findWord)
        }
      })
      setSelectedWords(initialWords)
    }

    if (topics.length > 0) {
      const sortedServerData = topics
        .slice()
        .sort(({ position: a }, { position: b }) => b - a)
      setLastPosition(sortedServerData[0].position)
    }
  }, [selectedTopic, topics])

  const invalidateCustomTopicsData = () => {
    queryClient.invalidateQueries({
      queryKey: [CustomTopicsApiQueries.GetCustomTopics],
    })
  }

  const onWordSelection = (_: ChangeEvent<{}>, value: WordBaseData) => {
    if (value !== null && !selectedWords.includes(value) && !isLimitExceeded) {
      setSelectedWords((oldArray) => [...oldArray, value])
    }
  }

  const changeOrder = (newOrdered: WordBaseData[]) => {
    setSelectedWords(newOrdered)
  }

  const removeWord = async (wordId: number) => {
    setSelectedWords(selectedWords.filter((item) => item.wordId !== wordId))
    if (selectedTopic) {
      await invokeDeleteOrganizationTopicWordV5({
        topicId: selectedTopic.id,
        wordId,
      })
      invalidateCustomTopicsData()
    }
  }

  const saveTopic = async () => {
    const wordsToSave = selectedWords.map((word, index) => ({
      wordId: word.wordId,
      position: index,
    }))
    const payload: PayloadSyncOrganizationTopicsRequestV5 = {
      email,
      topics: [
        {
          id: topicId,
          words: wordsToSave,
          title: topicName,
          position: selectedTopic?.position ?? lastPosition + 1,
        },
      ],
    }
    const response = await invokeCustomTopicsApi(payload)
    if (response instanceof Error) {
      throw new Error(response.message)
    }
    changeStep(CustomTopicsSteps.InitialStep)
    invalidateCustomTopicsData()
  }

  const deleteTopic = async (id: string) => {
    await invokeDeleteOrganizationTopicV5({ topicId: id })
    changeStep(CustomTopicsSteps.InitialStep)
    invalidateCustomTopicsData()
  }

  return (
    <Container className={classes.mainContainer}>
      <Container maxWidth="sm" className={classes.innerContainer}>
        <IconButton
          className={classes.backButtonContainer}
          onClick={() => changeStep(CustomTopicsSteps.InitialStep)}
        >
          <ArrowBackIcon className={classes.iconBack} />
        </IconButton>
        <TextField
          label="Topic"
          value={topicName}
          onChange={(event) => setTopicName(event.target.value)}
          variant="filled"
          fullWidth
        />

        <Box className={classes.termsSection}>
          {wordsData.length > 0 && (
            <Autocomplete
              // need this to rerender it and clear after adding to the list (common solution)
              key={selectedWords.length}
              className={classes.autocomplete}
              filterOptions={filterOptions}
              fullWidth
              // @ts-expect-error
              onChange={onWordSelection}
              options={wordsData}
              getOptionLabel={(option) =>
                (option as WordBaseData).enTranslation ?? ''
              }
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label="Add term" />
              )}
            />
          )}

          {isLimitExceeded && (
            <Box className={classes.wordLimitContainer}>
              <Typography className={classes.wordLimitText} variant="body1">
                Only 30 terms allowed
              </Typography>
            </Box>
          )}

          {selectedWords.length > 0 && (
            <DraggableList
              wordsList={selectedWords}
              changeOrder={changeOrder}
              removeWord={removeWord}
            />
          )}

          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {selectedWords.length > 0 && (
              <Button
                className={classes.saveButton}
                variant="contained"
                disabled={isLimitExceeded}
                color="primary"
                onClick={saveTopic}
              >
                SAVE
              </Button>
            )}

            {selectedTopic && (
              <AlertDialog
                buttonCopy="DELETE TOPIC"
                mainCopy="You can't undo this action"
                title="Delete current topic?"
                onConfirm={() => {
                  deleteTopic(selectedTopic.id)
                }}
                buttonClassName={classes.deleteButton}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Container>
  )
}

export default EditTopicStep
